import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedModule } from '../shared.module';
import { HomeComponent } from './home.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { FarmacovigilanzaComponent } from '../farmacovigilanza/farmacovigilanza.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        NgbModule,
        SlickCarouselModule,
        SharedModule
    ],
    declarations: [HomeComponent,PrivacyPolicyComponent,FarmacovigilanzaComponent],
    exports: [HomeComponent,PrivacyPolicyComponent,FarmacovigilanzaComponent],
    providers: []
})
export class HomeModule { }
