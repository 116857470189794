import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GaEventDirective } from './shared/directives/gaEvent';

@NgModule({
    imports: [CommonModule],
    declarations: [GaEventDirective],
    exports: [GaEventDirective, CommonModule]
})
export class SharedModule { }