<nav class="navbar navbar-expand-lg navbar-absolute navbar-style" color-on-scroll="500">
    <div class="container container-header">
        <div class="navbar-translate">
            <div class="navbar-brand">
                <a href="/"><img class="brand-image" src="./assets/img/logo_msd_verde.png" alt="MSD"></a>
                <span class="p-patrocinio-black">con il patrocinio di </span>   
                <a href="https://www.siaarti.it/" target="_Blank" rel="noopener noreferrer"><img src="./assets/img/logo-siaarti.png" class="img-fluid logo-siaarti" alt="Siaarti"></a>
            </div>

            <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse"
                data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
            </button>
        </div>
        <div class="navbar-collapse" id="navbarToggler">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link">Paura del Buio</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#FAQ" (click)="sidebarClose();">FAQ</a>
                </li>
            </ul>
        </div>
    </div>
</nav>