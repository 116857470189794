<div class="wrapper">
    <div class="main">
        <div class="section">
            <div class="container-page">
                <div class="row">
                    <div class="col-md-12 ml-auto padding-t-b-15" [innerHTML]="pageContent"></div>  
                </div>
            </div>
        </div>
    </div>
</div>
