<div class="wrapper">
    <div class="main">
        <div class="section">
            <div class="container-page">
                <div class="row">
                     <div class="col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto padding-0" [innerHTML]="pageContent"></div> 
                </div>
            </div>
        </div>
    </div>
</div>