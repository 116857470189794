export class MetaTag {
    title: string;
    description: string;
    image: string;
    urlPage: string;
    keywords: string;
    robots?: string;
    ogLocale?: string;
    ogType?: string;
    ogSiteName?: string;
    twitterCard?: string;

    constructor (){
        this.robots = "INDEX, FOLLOW";
        this.ogLocale = "it_IT";
        this.ogType = "article";
        this.ogSiteName = "Ad occhi aperti";
        this.twitterCard = "summary";
     }
}