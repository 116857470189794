import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { MetaTag } from '../models/metaTag.models';

@Injectable({
    providedIn: 'root',
})
export class SeoService {

   defaultMetaTag = new MetaTag();

    constructor(
        private titlePage: Title,
        private meta: Meta
    ) { }

    setMetaTags(metaTag: MetaTag ) {
        this.titlePage.setTitle(metaTag.title);
        this.meta.updateTag({ name: 'robots', content: metaTag.robots ? metaTag.robots : this.defaultMetaTag.robots });
        this.meta.updateTag({ name: 'keywords', content: metaTag.keywords });
        this.meta.updateTag({ name: 'description', content: metaTag.description });
        this.meta.updateTag({ property: 'og:locale', content: metaTag.ogLocale ? metaTag.ogLocale : this.defaultMetaTag.ogLocale });
        this.meta.updateTag({ property: 'og:type', content: metaTag.ogType ? metaTag.ogType : this.defaultMetaTag.ogType });
        this.meta.updateTag({ property: 'og:title', content: metaTag.title });
        this.meta.updateTag({ property: 'og:description', content: metaTag.description });
        this.meta.updateTag({ property: 'og:url', content: metaTag.urlPage });
        this.meta.updateTag({ property: 'og:site_name', content: metaTag.ogSiteName ? metaTag.ogSiteName : this.defaultMetaTag.ogSiteName });
        this.meta.updateTag({ property: 'og:image', content: metaTag.image });
        this.meta.updateTag({ property: 'twitter:card', content: metaTag.twitterCard ? metaTag.twitterCard : this.defaultMetaTag.twitterCard });
        this.meta.updateTag({ property: 'twitter:description', content: metaTag.description });
        this.meta.updateTag({ property: 'twitter:title', content: metaTag.title });
        this.meta.updateTag({ property: 'twitter:image', content: metaTag.image });
        this.meta.updateTag({ property: 'twitter:url', content: metaTag.urlPage });
    }
}