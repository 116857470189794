import { StaticService } from './../shared/services/static.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-farmacovigilanza',
  templateUrl: './farmacovigilanza.component.html',
  styleUrls: ['./farmacovigilanza.component.scss']
})
export class FarmacovigilanzaComponent implements OnInit {

  constructor(
    private staticService: StaticService,
    private domSanitizer: DomSanitizer
  ) { }

  pageContent: SafeHtml;

  ngOnInit(): void {
    this.staticService.getFarmacovigilanza().subscribe(res => {
      this.pageContent = this.domSanitizer.bypassSecurityTrustHtml(res);
    });
  }
}
