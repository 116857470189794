import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getPrivacyPolicy(): Observable<string> {
    return this.httpClient.get(environment.staticUrl + '/privacy-policy.html', {
      headers: {
        'Accept': 'text/html',
      },
      responseType: 'text'
    });
  }

  getFarmacovigilanza(): Observable<string> {
    return this.httpClient.get(environment.staticUrl + '/farmacovigilanza.html', {
      headers: {
        'Accept': 'text/html',
      },
      responseType: 'text'
    });
  }
}
