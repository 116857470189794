<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-12 mr-auto ml-auto">
                <nav class="footer-nav text-center">
                    <ul>
                        <li><a href="/#ADOCCHIAPERTI">#adocchiaperti</a></li>
                        <li><a href="/#FAQ">FAQ</a></li>
                        <li><a href="privacy-policy" target="_Blank" rel="noopener noreferrer">Privacy policy</a></li>
                        <li><a href="farmacovigilanza" target="_Blank" rel="noopener noreferrer">Farmacovigilanza</a></li>
                        <li><button class="ot-sdk-show-settings cusrsor-hand button-cookie">Preferenze Cookie</button></li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-12 mr-auto ml-auto">
                <div class="credits text-center">
                    <span class="copyright display-footer-desktop">
                        Copyright © {{year}} MSD Italia Srl. All rights reserved. | IT-XBR-00028-W-12/2021
                    </span>
                    <span class="copyright display-footer-mobile">
                        Copyright © {{year}} MSD Italia Srl. All rights reserved. <br> IT-XBR-00028-W-12/2021
                    </span>
                    <img src="./assets/img/logo_msd_verde.png" class="img-fluid logo-msd" alt="MSD Italia">
                    <br class="display-footer-mobile">
                    <span class="p-patrocinio">Con il patrocinio di </span>
                    <a href="https://www.siaarti.it/" target="_Blank" rel="noopener noreferrer"><img src="./assets/img/logo-siaarti.png" class="img-fluid logo-siaarti" alt="Siaarti"></a>
                </div>
            </div>
            <div class="col-md-12 mr-auto ml-auto">
                <div class="credits text-center">
                    <span class="copyright display-footer-desktop">
                        <a class="p-patrocinio" href="https://www.msd.com/our-commitment-to-accessibility-for-all/" target="_Blank" rel="noopener noreferrer" rel="noopener">Accessibilità</a>    
                        <span class="p-patrocinio">  </span>
                        <a href="https://www.essentialaccessibility.com/msd" target="_Blank" rel="noopener noreferrer"><img class="logo aligncenter" alt="L’icona Web Accessibility serve a scaricare il software per tecnologia assistiva eSSENTIAL Accessibility per le persone con disabilità fisiche. E' un'iniziativa a supporto della comunità dei disabili che dimostra il nostro impegno a sostegno della Diversità, Inclusione e Responsabilità Sociale." src="./assets/img/icons/accessibility.png"></a>    
                    </span>
                    <span class="copyright display-footer-mobile">
                        <a class="p-patrocinio" id="accebility-link"  href="https://www.msd.com/our-commitment-to-accessibility-for-all/" target="_Blank" rel="noopener noreferrer" rel="noopener">Accessibilità</a>    
                        <span class="p-patrocinio">  </span>
                        <a id="accebility-icon"  href="https://www.essentialaccessibility.com/msd" target="_Blank" rel="noopener noreferrer"><img class="logo aligncenter" alt="L’icona Web Accessibility serve a scaricare il software per tecnologia assistiva eSSENTIAL Accessibility per le persone con disabilità fisiche. E' un'iniziativa a supporto della comunità dei disabili che dimostra il nostro impegno a sostegno della Diversità, Inclusione e Responsabilità Sociale." src="./assets/img/icons/accessibility.png"></a>    
                    </span>
                    
                    
                    
                </div>
            </div>
        </div>
    </div>
</footer>