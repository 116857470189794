import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

declare var $;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class HomeComponent implements OnInit {
    constructor(
        private titleService: Title,
        private metaTagService: Meta
    ) { }

    slider01 = [
        { html: '<h3>Qual è la paura più infondata e frequente legata all’anestesia?</h3><p class="text-italic p-faq-small">Il non svegliarsi dal sonno farmacologicamente indotto o risvegliarsi anzitempo nel mezzo dell’operazione.<br> Eppure si tratta di un eventualità estremamente rara.</p><p class="p-faq-small"><span class="font-msd">Risposta:</span> L’anestesista rimarrà con il paziente durante tutta la procedura e, guidato dalla strumentazione per il monitoraggio della profondità dell’anestesia e dei parametri vitali (es. pressione, battito cardiaco, respirazione etc..) scrupolosamente controllati durante l’operazione, interverrà continuando a somministrare l’anestetico per garantire continuamente uno stato controllato di incoscienza e controllando la profondità dell’anestesia, evitando un recupero spontaneo. Anche il risveglio è oggi più sicuro: l’anestesia crea una condizione di sonno reversibile e quindi un successivo risveglio controllato e regolato da strumenti che consentono di guidare l’anestesista fino al recupero totale del paziente e da farmaci sempre più sicuri, tollerati ed efficaci.</p>' },
        { html: '<h3>Quando non posso evitare di fare l’anestesia generale?</h3><p class="p-faq"><span class="font-msd">Risposta:</span> L’anestesia generale è necessaria in tutti i casi in cui non sia possibile effettuarne un’anestesia loco-regionale, ovvero quando l’intervento  ha una durata temporale significativa che in alcuni casi potrebbe essere mal tollerata e stancante dal paziente oppure le zone del corpo da operare interessano zone del corpo strettamente coinvolte nell’atto respiratorio.</p>' },
        { html: '<h3>L’anestesia generale è pericolosa?</h3><p class="p-faq"><span class="font-msd">Risposta:</span> L’anestesia si è evoluta molto nel corso degli anni e, nonostante sia una delle branche più giovani della medicina, ha fatto passi da gigante grazie all’utilizzo di nuove tecnologie e di farmaci sempre più sicuri che consentono di guidare l’anestesista per mettere in sicurezza il paziente fino al risveglio.</p>' },
        { html: '<h3>Ci sono rischi?</h3><p class="p-faq"><span class="font-msd">Risposta:</span> Non esistono procedure scevre da rischi. Molto dipende dal quadro clinico pregresso del paziente che si sottopone all’anestesia. Il rischio maggiore è legato ad una possibile allergia al farmaco anestetico. Perciò è importante informare il team medico deve essere aggiornato sulle allergie e intolleranze (comprese quelle alimentari), eventuali reazioni precedenti a qualche farmaco o l’uso concomitante di altri farmaci.</p>' },
        { html: '<h3>Quali sono gli effetti collaterali più frequenti dell’anestesia generale?</h3><p class="p-faq"><span class="font-msd">Risposta:</span> Nausea, Vomito, Secchezza delle fauci,  Dolore  o fastidio alla gola , sonnolenza prolungata. La risposta è soggettiva e, mediamente, le donne sono più sensibili degli uomini. E’ bene ricordare che i farmaci somministrati durante l’anestesia generale vengono completamente eliminati dall’organismo nel giro di poche ore e gli eventuali effetti collaterali,  se presenti,  saranno di breve durata.</p>' },
        { html: '<h3>Se si viene sottoposti ad anestesia generale si viene sempre anche “intubati”?</h3><p class="p-faq-small"><span class="font-msd">Risposta:</span> Non sempre si viene intubati dipende dal tipo d’intervento e dalla sua durata, ad ogni modo si possono utilizzare maschere facciali o dispositivi che si appoggiano alla trachea per garantire una perfetta ossigenazione. Come descritto durante l’anestesia generale è necessario ottenere la paralisi dei muscoli come quelli coinvolti nell’atto respiratorio autonomo. L’intubazione consente di instaurare una respirazione artificiale durante tutta la procedura e proteggere le vie aeree da eventuali secrezioni e/o vomito che potrebbe insorgere nella fase dell’induzione.<br>Ci sono anche operazioni di breve durata (massimo 30’) e poco invasive, in cui può essere effettuata una sedazione (blanda, media, profonda) che induce il sonno ma lascia integra l’attività respiratoria spontanea. Questa, al limite, necessiterà di supporto con mascherina.</p>' },
        { html: '<h3>L’intubazione può presentare inconvenienti?</h3><p class="p-faq"><span class="font-msd">Risposta:</span>L’intubazione è una manovra importante e delicata. Consiste, infatti, nell’infilare un tubo in trachea (inserendolo dalla bocca o dal naso) attraverso le corde vocali, per consentire la respirazione artificiale mediante collegamento ad una macchina. Talvolta può risultare difficile per la conformazione anatomica del paziente e presenta come eventuale effetto collaterale avverso, la possibilità di stimolare un broncospasmo. Quando, però, l’intubazione risulta rischiosa alla visita anestesiologica, per una particolare conformazione anatomica del paziente, si cercano vie alternative. Ad esempio si ricorre alla «maschera laringea»: meno invasiva e traumatica dell’intubazione, occupa lo spazio tra bocca e faringe, e quindi più agevole in questi casi.</p>' },
        { html: '<h3>Che cosa può succedere di imprevedibile in sala operatoria? Come si rimedia?</h3><p class="p-faq"><span class="font-msd">Risposta:</span> L’anestesista sorveglia costantemente il paziente, osservandolo direttamente e con l’aiuto delle strumentazioni a disposizione ,  permettendogli di  monitorizzare le funzioni vitali: il respiro, la pressione arteriosa, la frequenza cardiaca. E’ pronto ad intervenire per gestire ogni evenienza imprevedibile: oggi le possibilità di rimedio a disposizione dei medici in sala sono notevolmente migliorate, con una conseguente riduzione dei rischi. I rischi sono paragonabili ad un volo in aereo.<br>Infine, se insorgono nel corso dell’ operazione, complicazioni chirurgiche, l’anestesista provvede a prolungare o adeguare alla nuova situazione l’anestesia.</p>' },
        { html: '<h3>Prima di entrare in sala operatoria bisogna togliersi ori, protesi, smalto, trucco agli occhi… In che modo questi possono interferire con l’anestesia o con l’intervento?</h3><p class="p-faq"><span class="font-msd">Risposta:</span> Può sembrare strano, ma in realtà interferiscono con entrambi. Il colorito del contorno occhi o delle unghie è un indice importante di mutamento delle condizioni di ossigenazione del paziente. Il suo corpo deve essere assolutamente libero da impedimenti. La crema potrebbe fare da isolante per gli elettrodi, i metalli possano interferire con alcune strumentazioni.</p>' },
        { html: '<h3>Perché a volte si arriva in camera ancora addormentati, e altre volte si è svegli?</h3><p class="p-faq"><span class="font-msd">Risposta:</span> Il risveglio, come fase dell’anestesia, è la ripresa autonoma delle funzioni vitali che deve avvenire in sala operatoria sotto il controllo dell’anestesista. Poi il paziente può continuare a dormire oppure no, ma si tratterà solo di stato soporoso.</p>' },
        { html: '<h3>Se è andato tutto bene una volta, significa che tendenzialmente l’anestesia non crea problemi?</h3><p class="p-faq"><span class="font-msd">Risposta:</span> In generale sì, ma solo se nel frattempo non sono mutate le nostre condizioni fisiche generali.</p>' }
    ];

    slider02 = [
        { html: '<p class="p-slider-top">ipnosi (incoscienza,perdita memoria) &#8594; attraverso un farmaco ipnoinducente, generalmente un gas inalatorio o un farmaco endovenoso</p>' },
        { html: '<p class="p-slider-top">analgesia (perdita di risposta al dolore) &#8594; attraverso un farmaco antidolorifico iniettato per via endovenosa</p>' },
        { html: '<p class="p-slider-top">rilassamento muscolare (perdita di riflessi motori, rilassamento muscolare scheletrico) &#8594; farmaco miorilassante iniettato per via endovenosa</p>' }
    ]

    slideConfig = {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrows": true,
        "prevArrow": '<button type="button" class="home-arrow home-arrow-prev"><img src="../../assets/img/arrow-left.png" class="img-fluid"></button>',
        "nextArrow": '<button type="button" class="home-arrow home-arrow-next"><img src="../../assets/img/arrow-right.png" class="img-fluid"></button>',
        "dots": true,
        "dotsClass": "slick-dots",
        "autoplay": true,
        "autoplaySpeed": 5000,
        "infinite": true
    };

    slideConfigMobile = {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrows": true,
        "prevArrow": '<button type="button" class="home-arrow home-arrow-prev"><img src="../../assets/img/arrow-left.png" class="img-fluid"></button>',
        "nextArrow": '<button type="button" class="home-arrow home-arrow-next"><img src="../../assets/img/arrow-right.png" class="img-fluid"></button>',
        "dots": true,
        "dotsClass": "slick-dots",
        "autoplay": true,
        "autoplaySpeed": 5000,
        "infinite": true
    };

    slideConfig2 = {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrows": true,
        "prevArrow": '<button type="button" class="home-arrow-2 home-arrow-prev-2"><img src="../../assets/img/arrow-left-white.png" class="img-fluid"></button>',
        "nextArrow": '<button type="button" class="home-arrow-2 home-arrow-next-2"><img src="../../assets/img/arrow-right-white.png" class="img-fluid"></button>',
        "dots": false,
        "dotsClass": "slick-dots",
        "autoplay": true,
        "autoplaySpeed": 5000,
        "infinite": true
    };

    addSlide() {
        // this.slides.push({img: "http://"})
    }

    removeSlide() {
        // this.slides.length = this.slides.length - 1;
    }

    slickInit(e) {
        // console.log('slick initialized');
    }

    breakpoint(e) { }

    afterChange(e) { }

    beforeChange(e) {
        var slideNext = e.nextSlide
        $('.slide-relation').slick('slickGoTo', slideNext);
    }

    ngOnInit() {
        this.titleService.setTitle("Adocchiaperti - Informazioni sull'anestesia generale");

        this.metaTagService.addTags([
            { name: 'description', content: "Che cosa è l'anestesia generale? Un trattamento che consente al paziente sottoposto ad intervento chirurgico di perdere lo stato di coscienza, non sentire dolore, non muoversi e non ricordare quanto accade." },
            { name: 'keywords', content: "anestesia, ipnosi, analgesia, rilassamento, rianimazione, anestetico, risveglio" },
            { property: 'og:title', content: "Adocchiaperti - Informazioni sull'anestesia generale" },
            { property: 'og:description', content: "Che cosa è l'anestesia generale? Un trattamento che consente al paziente sottoposto ad intervento chirurgico di perdere lo stato di coscienza, non sentire dolore, non muoversi e non ricordare quanto accade." },
            { property: 'og:type', content: "article" },
            // { property: 'og:image', content: '' },
            { property: 'og:url', content: 'https://www.adocchiaperti.msdsalute.it' },
            { property: 'twitter:title', content: "Adocchiaperti - Informazioni sull'anestesia generale" },
            { property: 'twitter:description', content: "Che cosa è l'anestesia generale? Un trattamento che consente al paziente sottoposto ad intervento chirurgico di perdere lo stato di coscienza, non sentire dolore, non muoversi e non ricordare quanto accade." },
            // { property: 'twitter:image', content: '' },
            { property: 'twitter:card', content: 'summary' }
        ]);
    }
}
