<div class="wrapper">
    <div class="main">
        <div class="section">
            <div class="container-page">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0">
                        <div style="position: relative; display: block;">
                            <div style="padding-top: 56.25%;"><iframe alt="video ho paura del buio" aria-label="video ho paura del buio"
                                    src="https://players.brightcove.net/4090876667001/default_default/index.html?videoId=6278178578001 "
                                    allowfullscreen="" allow="encrypted-media"
                                    style="position: absolute; top: 7pt; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-10 col-lg-10 mr-auto ml-auto">


                        <p class="font-weight-bold text-center margin-top-bottom-3-2vw">
                            L’ignoto, come il buio, fa paura. <br>
                            Soprattutto quando si tratta della salute di chi amiamo.<br>
                            E per affrontare il Buio della Paura, a volte serve che qualcuno ci indichi la strada
                            accendendo in noi la luce della consapevolezza.<br>
                            Sono molte le Persone a temere l’anestesia, e solo il dialogo con il proprio anestesista
                            rianimatore può aiutarle a vivere più serenamente il percorso perioperatorio.<br>
                            Perchè la paura si supera insieme.<br>
                            Segui la campagna <b>#oltreilbuio</b> sui canali social MSD Italia<br> </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <h2 class="font-msd">
                            <span class="letter-underlined">C</span>HE COSA E' L'ANESTESIA GENERALE
                        </h2>
                        <p>
                            Per anestesia generale si intende un trattamento che consente al paziente sottoposto ad
                            intervento chirurgico di <span class="font-weight-bold">perdere lo stato di
                                coscienza,</span> in modo da non <span class="font-weight-bold">sentire alcun dolore,
                                non muoversi</span> e di non ricordare quanto accade.
                        </p>

                        <p>
                            Il sonno che viene indotto dall’anestesia generale è tuttavia ben diverso dal sonno
                            regolare, <span class="font-weight-bold">il cervello anestetizzato non conserva ricordi e
                                non risponde ai segnali del dolore,</span>
                            a differenza di quanto succederebbe normalmente in caso di stimoli forti come quelli causati
                            da un intervento chirurgico.
                        </p>

                        <p class="font-weight-bold">
                            L’anestesia generale è una condizione farmacologicamente indotta con l’obiettivo di ottenere
                            3 componenti:
                        </p>
                    </div>
                </div>

                <div class="row box-msd-full margin-top-bottom-2vw">
                    <div class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0 margin-top-bottom-2vw">
                        <div class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0">
                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig2"
                                (init)="slickInit($event)" (breakpoint)="breakpoint($event)"
                                (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                                <div ngxSlickItem *ngFor="let slide of slider02" class="slide">
                                    <div style="margin: 0 40px;" [innerHTML]="slide.html"></div>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <p>
                            L’anestesista può scegliere di procedere con un’anestesia generale, escludendo forme di
                            sedazione più leggere o l’anestesia locale o epidurale, in situazioni specifiche come ad
                            esempio per gli interventi molto lunghi o interventi che impattano sulla respirazione e
                            quindi quelli che interessano il torace o l’addome superiore.
                        </p>

                        <p>
                            Non ci sono controindicazioni assolute all’anestesia generale, tuttavia ci sono numerose
                            condizioni che, già durante la visita anestesiologica, richiedono <span
                                class="font-weight-bold">un’attenta valutazione da
                                parte del medico anestesista,</span> tra cui per esempio:
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div
                        class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0 margin-top-bottom-2vw display-mobile-none">
                        <img class="img-fluid img-centered" src="./assets/img/banner-valutazione.jpg"
                            alt="Ad occhi aperti">
                    </div>

                    <div
                        class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0 margin-top-bottom-2vw display-desktop-none">
                        <img class="img-fluid img-centered" src="./assets/img/banner-valutazione-mobile-1.jpg"
                            alt="Ad occhi aperti">
                    </div>

                    <div
                        class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0 margin-top-bottom-2vw display-desktop-none">
                        <img class="img-fluid img-centered" src="./assets/img/banner-valutazione-mobile-2.jpg"
                            alt="Ad occhi aperti">
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <p>
                            Lo stato di salute del paziente dovrebbe essere portato nelle condizioni migliori possibili,
                            quando ci sia margine di intervento, prima della chirurgia. (Maggiori dettagli nel paragrafo
                            “visita anestesiologica”)
                        </p>

                        <p class="font-weight-bold">
                            La morte a causa dell’anestesia generale è rara <span class="text-underlined">e si stima che
                                sia pari a circa 1 su 150.000 pazienti</span> (0.0007%).
                        </p>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0 margin-top-bottom-2vw">
                        <img class="img-fluid box-image-mobile-none box-image-tablet-none"
                            src="./assets/img/banner-center.jpg" alt="ad occhi aperti banner">
                        <img class="img-fluid box-image-desktop-none box-image-mobile-none"
                            src="./assets/img/banner-center-tablet.jpg" alt="ad occhi aperti tablet">
                        <img class="img-fluid box-image-desktop-none box-image-tablet-none"
                            src="./assets/img/banner-center-mobile.jpg" alt="ad occhi aperti mobile">

                        <div class="box-white-description-100">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-auto ml-auto">
                                <p class="box-white-title-100">
                                    Prima dell’intervento: la visita anestesiologica
                                </p>
                                <p>
                                    La <span class="font-weight-bold">visita anestesiologica</span> viene condotta dal
                                    medico anestesista-rianimatore, qualche
                                    giorno prima dell’intervento e permette di <span
                                        class="font-weight-bold">pianificare con attenzione il tipo di
                                        anestesia, ottenendo il miglior compromesso possibile tra rischi e benefici per
                                        il
                                        singolo paziente.</span>
                                </p>
                                <p class="box-white">
                                    Sapere prima quali saranno i temi discussi durante la visita anestesiologica (di
                                    seguito elencati) può essere molto utile per far sì che il paziente possa arrivare
                                    pronto all’incontro con tutte le informazioni necessarie per la valutazione da parte
                                    dell’anestesista-rianimatore.
                                </p>
                                <p>
                                    Oltre a valutare gli esami clinici, il medico anestesista-rianimatore effettuerà una
                                    visita completa dalla quale ricaverà informazioni come l’eta, il peso, l’altezza, il
                                    sesso, l’indice di massa corporea e porrà una serie di domande al paziente, quali:
                                </p>
                                <ul class="box-margin">
                                    <li>lo stato di salute generale, inclusi eventuali cambiamenti recenti,</li>
                                    <li>malattie pregresse</li>
                                    <li> se si stanno assumendo farmaci</li>
                                    <li>allergie a farmaci o ad altro</li>
                                    <li>informazioni e abitudini alimentari, abitudine al fumo, consumo di alcolici e/o
                                        altre sostanze</li>
                                    <li>disturbi di salute cronici: pressione alta, diabete, malattie cardiache ,asma,
                                        sindrome delle apnee notturne</li>
                                    <li>interventi subiti in passato</li>
                                    <li>passate esperienze con l’anestesia e relativi eventuali problemi.</li>
                                </ul>
                                <p>
                                    Come dicevamo, lo stato di salute e le condizioni del paziente dovrebbero essere
                                    portate nelle condizioni migliori possibili prima dell’intervento, per ottimizzarne
                                    l’esito e ridurne i rischi. Per tale ragione, durante la visita anestesiologica,
                                    verranno fornite alcune indicazioni da seguire come:
                                </p>
                            </div>

                            <div
                                class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto padding-0 margin-top-bottom-2vw box-image-mobile-none box-image-tablet-none">

                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6 mr-auto ml-auto box-50-left">
                                        <ul>
                                            <li class="box-white-title-100">Digiuno pre-operatorio:</li>
                                        </ul>
                                        <p>
                                            L’anestesia generale attenua la naturale predisposizione del corpo a
                                            mantenere
                                            gli
                                            alimenti nello stomaco e fuori dai polmoni, quindi il paziente verrà
                                            istruito
                                            sui
                                            tempi necessari di digiuno e sull’assunzione dei liquidi prima
                                            dell’intervento.
                                            Generalmente i tempi
                                            vengono indicati dall'anestesista.
                                        </p>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6 mr-auto ml-auto box-50-right">
                                        <ul>
                                            <li class="box-white-title-100">Sospensione di farmaci:</li>
                                        </ul>
                                        <p>
                                            Potrebbe essere necessario evitare alcuni farmaci, ad esempio i
                                            fluidificanti del sangue come l’aspirina e soprattutto gli anticoagulanti
                                            per diversi giorni prima dell’intervento.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 mr-auto ml-auto box-50-left box-image-desktop-none">
                                <ul>
                                    <li class="box-white-title-100">Digiuno pre-operatorio:</li>
                                </ul>
                                <p>
                                    L’anestesia generale attenua la naturale predisposizione del corpo a
                                    mantenere
                                    gli
                                    alimenti nello stomaco e fuori dai polmoni, quindi il paziente verrà
                                    istruito
                                    sui
                                    tempi necessari di digiuno e sull’assunzione dei liquidi prima
                                    dell’intervento.
                                    Generalmente i tempi
                                    vengono indicati dall'anestesista
                                </p>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 mr-auto ml-auto box-50-right box-image-desktop-none">
                                <ul>
                                    <li class="box-white-title-100">Sospensione di farmaci:</li>
                                </ul>
                                <p>
                                    Potrebbe essere necessario evitare alcuni farmaci, ad esempio i
                                    fluidificanti del sangue come l’aspirina e soprattutto gli anticoagulanti
                                    per diversi giorni prima dell’intervento.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto box-mobile-margin-top">
                        <h2>
                            Durante l’intervento: induzione e mantenimento dell’anestesia
                        </h2>
                        <p>
                            L’Induzione dell’anestesia avviene attraverso la somministrazione di un mix di farmaci
                            ipnotici, analgesici e miorilassanti, che possono essere dati a diversi dosaggi in modo da
                            ottenere un’anestesia più o meno profonda.
                        </p>

                        <div class="box-msd margin-top-bottom-2vw">
                            <ul>
                                <li>
                                    L’anestetico ha effetto molto rapido, il paziente perde coscienza in meno di un
                                    minuto senza provare alcuna sensazione sgradevole, come se si addormentasse.
                                </li>
                                <li>
                                    La perdita di coscienza del paziente è seguita dalla completa paralisi dei muscoli
                                    del corpo e ovviamente della respirazione, resa possibile grazie ai farmaci
                                    utilizzati
                                    dall’anestesista noti come miorilassanti o bloccanti neuro-muscolari. Questi
                                    farmaci, permettono di salvaguardare e proteggere le vie respiratorie, rendendo
                                    possibile
                                    l’intubazione tracheale e permettendo una ventilazione artificiale
                                </li>
                            </ul>
                        </div>

                        <p>
                            La profondità dell’anestesia, il grado di paralisi dei muscoli, i parametri respiratori e
                            cardiovascolari vengono monitorati continuamente, dall’inizio alla fine della procedura,
                            attraverso vari dispositivi in modo tale che l’anestesista-rianimatore abbia sotto controllo
                            tutte le funzioni vitali in ogni momento, permettendogli qualora fosse necessario,
                            d’intervenire tempestivamente.
                        </p>

                        <p>
                            Perchè è necessario bloccare l’attività muscolare? La paralisi muscolare ha sia lo scopo di
                            favorire il lavoro del chirurgo grazie al rilassamento muscolare, evitando che il paziente
                            possa muoversi bruscamente e/o intralciandone l’operato, sia quella di permettere la manovra
                            d’intubazione necessaria per effettuare la respirazione artificiale del paziente,
                            proteggendo le vie respiratorie.
                        </p>

                        <p class="box-msd margin-top-bottom-2vw">
                            L’anestesista-rianimatore, rimarrà con il paziente durante tutta la procedura e, guidato
                            dalle strumentazioni a sua disposizione, effettuerà il monitoraggio della profondità
                            dell’anestesia e dei parametri vitali (es.pressione, battico cardiaco, ossigenzione etc)
                            scrupolosamente controllati durante l’operazione, interverrà somministrando l’anestetico per
                            garantire continuamente uno stato controllato di incoscienza ed eventualmente anche degli
                            antidolorifici in grado di garantire assenza di dolore al risveglio.
                        </p>
                    </div>
                </div>

                <div class="row box-msd-full margin-top-bottom-2vw">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto padding-t-b-2">
                        <h2 class="font-white">
                            Fine Intervento: recupero e risveglio
                        </h2>
                        <p class="font-white">
                            #adocchiaperti
                        </p>
                        <p class="font-white">
                            Al termine dell’intervento l’anestesista interromperà gradualmente la somministrazione
                            dell’anestetico e il paziente pian piano si risveglierà. Il risveglio è quindi ottenuto
                            attraverso la progressiva eliminazione dall’organismo dei residui dei farmaci anestetici
                            usati. L’anestesia può essere smaltita attraverso un recupero spontaneo e quindi basterà
                            attendere del tempo necessario affinché i farmaci vengano smaltiti oppure utilizzare farmaci
                            antagonisti dell’anestetico stesso.
                        </p>

                        <p class="font-white">
                            Ad esempio, una volta interrotta la somministrazione dei farmaci, i muscoli del paziente,
                            inclusi i muscoli coinvolti nella respirazione, escono gradualmente dallo stato di paralisi
                            indotto in precedenza dai bloccanti neuromuscolari, consentendo la ripresa della
                            respirazione
                            spontanea.
                        </p>

                        <p class="font-white">
                            Per accelerare il tempo di ripresa dei muscoli e soprattutto per eliminare ogni traccia di
                            anestetico in circolo, che potrebbe determinare nuovamente la paralisi dei muscoli
                            respiratori e creare complicanze, vengono utilizzati farmaci innovativi che antagonizzano in
                            modo selettivo i farmaci bloccanti neuromuscolari. I farmaci antagonisti “catturano” quelli
                            usati per la paralisi muscolare e li “spazzano via” eliminandoli dal corpo del paziente che,
                            in pochissimi minuti, ritorna a respirare autonomamente.
                        </p>

                        <p class="font-white">
                            Questi farmaci consentono un recupero completo dei muscoli in meno di 3 minuti e quindi una
                            ripresa rapida e prevedibile della respirazione spontanea.
                        </p>

                        <p class="font-white">
                            In questa fase il paziente recupera alcune funzioni vitali di base (sensoriali e motorie),
                            fino ad arrivare a uno stato di coscienza minimo che coincide con il risveglio .
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-10 col-lg-8 mr-auto ml-auto">
                        <p class="font-msd">
                            Il risveglio è un’azione naturale e quotidiana, un atto che compiamo in modo automatico.
                            Quando ci sottoponiamo a un intervento chirurgico, la paura più diffusa è quella di
                            non risvegliarsi più dalla anestesia. Eppure, l’intervento dell’anestesista crea una
                            condizione di sonno reversibile e quindi un risveglio controllato e regolato sia da
                            strumenti che consentono di guidare l’anestesista fino al recupero totale del paziente, sia
                            da farmaci sempre più sicuri, tollerati ed efficaci.
                        </p>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <p>
                            La sicurezza dell’anestesia moderna deriva dai progressi farmacologici e tecnologici e dalla
                            particolare preparazione dell’anestesista. Se ne parla poco, ma la moderna anestesiologia
                            offre sempre più sicurezza.
                        </p>

                        <p>
                            Ma perché l’anestesia fa così tanta paura? In generale si crede che provochi ansia la
                            sospensione dello stato cosciente, connessa all’anestesia generale. Tuttavia questa paura è
                            in gran parte un retaggio del passato. Non è infatti lontana l’epoca in cui l’anestesia era
                            un salto nel vuoto, una condizione di cui si aveva effettivamente scarso controllo.
                        </p>

                        <p>
                            Oggi abbiamo la possibilità di monitorizzare i principali parametri vitali del paziente
                            durante l’anestesia e di avere il quadro clinico sotto controllo in tempo reale. Inoltre,
                            abbiamo la possibilità di monitorare lo stato del sonno e della coscienza e questo aiuta
                            molto il medico nella prevenzione delle complicanze; ecco perchè i pazienti possono essere
                            tranquilli.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto margin-top-bottom-2vw">
                        <p class="box-msd">
                            Il monitoraggio in tempo reale consente al medico di mettere in atto tempestivamente le
                            contromisure quando uno o più parametri risultano significativamente alterati o di
                            intervenire con farmaci salvavita.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <p>
                            L’anestesia moderna è oggi sicura anche grazie ai nuovi farmaci e nuovi strumenti
                            tecnologici e grazie alla possibilità di personalizzare l’anestesia in base alle
                            caratteristiche dei pazienti . L’intera procedura è oggi più sicura anche per i pazienti
                            fragili, come gli anziani, i bambini o i grandi obesi.
                        </p>

                        <p>
                            La visita anestesiologica è il primo atto in cui si analizza il caso clinico e si disegna un
                            protocollo anestesiologico ad hoc che permette di rassicurare paziente. Il rapporto umano e
                            confidenziale tra paziente e anestesista è indispensabile per creare fiducia e rassicurare.
                        </p>

                        <p>
                            Un ruolo centrale nell’assicurare sempre più la sicurezza anestesiologica è affidato alle
                            società scientifiche come la <a class="font-msd font-weight-bold"
                                href="https://www.siaarti.it/" target="_Blank" rel="noopener noreferrer">SIAARTI
                                (Società Italiana di
                                Analgesia, Anestesia, Rianimazione
                                e Terapia intensiva)</a> che nel tempo ha emanato Linee guida e Buone pratiche cliniche
                            e quindi
                            degli standard operativi ai quali ogni centro ospedaliero deve attenersi per gestire
                            l’anestesia in maniera sicura e affidabile.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <h2 class="font-msd margin-top-bottom-3-2vw">
                            <span class="letter-underlined">C</span>HI E' E CHE COSA FA L'ANESTESISTA RIANIMATORE?
                        </h2>
                        <p>
                            Normalmente ci relazioniamo con il chirurgo perchè il suo lavoro è fondamentale per definire
                            tecnicamente ed operativamente le modalità dell’intervento.
                        </p>
                        <p>
                            Durante l'intervento il chirurgo non è solo in sala operatoria ma è affiancato da una serie
                            di figure professionali dal cui lavoro coordinato e multidisciplinare dipende grandemente
                            l'esito dell'intervento stesso.
                        </p>
                        <p>
                            Spesso il lavoro di tutte queste persone è totalmente sconosciuto, come quella
                            dell'anestesista che compie un lavoro importantissimo, viene visto dal paziente solo durante
                            la visita anestesiologica e all’inizio dell’intervento, ma non sempre il paziente sa che
                            l’anestesista ha una grossa responsabilità durante tutta la procedura chirurgica e anche nel
                            recupero post-operatorio.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <h2 class="text-center margin-top-bottom-3-2vw">
                            Cosa fa l’anestesista-rianimatore?
                        </h2>
                    </div>
                </div>

                <div class="row box-msd-full">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto padding-t-b-2">
                        <p class="font-white">
                            Prima di tutto è necessario chiarire che l’Anestesista-Rianimatore è un Medico.
                            Per diventare anestesista-rianimatore è necessario aver conseguito la Laurea in Medicina e
                            Chirurgia della durata di 6 anni ed aver completato un percorso di studi per ulteriori 5
                            anni affinchè si diventi specialisti in Anestesia-Rianimazione e Terapia del Dolore.
                            <br>
                            Il medico anestesista-rianimatore lavora non solo in sala operatoria, ma anche all’interno
                            dei reparti di rianimazione e terapia intensiva, spesso di guardia nel pronto soccorso e sul
                            territorio esercitando la sua attività in ambulanza e in elicottero per salvare le vite di
                            noi tutti.
                            <br>
                            Un'altra importante funzione è quello di gestire il dolore prima durante e dopo un
                            intervento, ma anche quella di curare il dolore cronico insieme ad altre figure
                            professionali. L’anestesista-rianimatore si occupa pure della gestione del fine vita, delle
                            cure palliative e, insieme ad un team multidisciplinare, dei trapianti d’organo in merito
                            alla decisione nella fase di espianto.
                        </p>
                        <p class="font-white">
                            E’ quindi chiaro che l’anestesista-rianimatore è fondamentale in tutto il percorso
                            chirurgico di un paziente.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <div class="margin-top-bottom-3-2vw">
                            <h2>
                                <span class="text-underlined-small">Prima dell’intervento:</span>
                            </h2>
                        </div>
                        <div class="box-margin-black">
                            <ul>
                                <li>
                                    deve garantire che il paziente sia in condizione per essere operato considerando
                                    tutti i rischi e le difficoltà che l'intervento può implicare, deve spiegare il tipo
                                    di
                                    anestesia che intende utilizzare e rispondere a tutte le domande che il paziente gli
                                    rivolge
                                    durante la visita anestesiologica
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <div class="margin-top-bottom-2-2vw">
                            <h2>
                                <span class="text-underlined-small">Durante l’intervento:</span>
                            </h2>
                        </div>

                        <div class="box-margin-black">
                            <ul>
                                <li>
                                    È l’unico responsabile della gestione anestesiologica del paziente sia all’induzione
                                    che
                                    al risveglio.
                                    Si occupa di somministrare il tipo di anestesia valutata come più adatta e di
                                    trattare il
                                    dolore,
                                    controlla lo stato di salute del paziente e i parametri vitali per tutta la durata
                                    dell’intervento scegliendo la modalità più adatta per intervenire e mettere in
                                    sicurezza il
                                    paziente in caso di valori alterati
                                </li>
                            </ul>
                        </div>
                        <div class="box-margin-black">
                            <ul>
                                <li>
                                    Si occupa del risveglio del paziente seguendo il recupero dello stato di coscienza,
                                    dell’attività muscolare e respiratoria
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <div class="margin-top-bottom-2-2vw">
                            <h2>
                                <span class="text-underlined-small">Dopo l’intervento:</span>
                            </h2>
                        </div>

                        <div class="box-margin-black">
                            <ul>
                                <li>
                                    Si occupa del controllo del dolore anche post-operatorio
                                </li>
                            </ul>
                        </div>
                        <div class="box-margin-black">
                            <ul>
                                <li>
                                    Segue il paziente fino alla sua ripresa completa che permette di escludere
                                    complicanze
                                    post-operatorie come quelle polmonare
                                </li>
                            </ul>
                        </div>
                        <div class="box-margin-black">
                            <ul>
                                <li>
                                    E’ il responsabile del trattamento del dolore acuto e cure palliative anche per
                                    patologia
                                    croniche non legate ad una procedura chirurgica
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div id="FAQ" class="row box-gray-full">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 mr-auto ml-auto">
                        <h2 class="font-white">
                            FAQ
                            <br>
                            Frequently asked Questions
                        </h2>

                        <div class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0 display-mobile-none">
                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                                (init)="slickInit($event)" (breakpoint)="breakpoint($event)"
                                (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                                <div ngxSlickItem *ngFor="let slide of slider01" class="slide">
                                    <div class="slider-container" [innerHTML]="slide.html"></div>
                                </div>
                            </ngx-slick-carousel>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0 display-desktop-none">
                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                [config]="slideConfigMobile" (init)="slickInit($event)"
                                (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                                (beforeChange)="beforeChange($event)">
                                <div ngxSlickItem *ngFor="let slide of slider01" class="slide">
                                    <div class="slider-container" [innerHTML]="slide.html"></div>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-10 col-sm-10 col-md-10 col-lg-10 mr-auto ml-auto padding-0">
                        <div class="margin-top-bottom-2-2vw">
                            <h2 class="text-center margin-top-bottom-3-2vw">
                                #ADOCC<span class="letter-underlined">H</span>IAPERTI
                            </h2>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0 margin-top-bottom-2vw">
                        <img class="img-fluid" src="./assets/img/banner-anestesia.jpg"
                            alt="Ad occhi aperti banner anestesia">
                        <h2 class="title-image-centered opacity05">#ADOCCHIAPERTI</h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-10 col-lg-10 mr-auto ml-auto">
                        <p class="font-weight-bold text-center margin-top-bottom-3-2vw"></p>

                        <h2 class="font-msd text-center text-italic margin-top-bottom-2-2vw">
                            prima e dopo l’anestesia
                        </h2>
                        <p class="font-weight-bold text-center margin-top-bottom-3-2vw">
                            Esistono moltissimi interrogativi attorno al tema dell'Anestesia Generale!
                            <br>
                            E’ #adocchiaperti l’esito positivo di un intervento chirurgico, ma anche l’uscita dal
                            torpore
                            della disinformazione, per realizzare quanto l’anestesia sia una procedura sicura che non
                            deve fare paura.
                            <br>
                            Qui troverai le corrette infomazioni su cosa è l’Anestesia generale, l’importanza del ruolo
                            dell’Anestesista e l’impatto delle sue scelte nel percorso chirurgico del paziente.
                        </p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 mr-auto ml-auto padding-0">
                        <div style="position: relative; display: block;">
                            <div style="padding-top: 56.25%;"><iframe alt="video generalista" aria-label="video generalista"
                                    src="https://players.brightcove.net/4090876667001/D4NhxqUv6_default/index.html?videoId=6116386145001"
                                    allowfullscreen="" allow="encrypted-media"
                                    style="position: absolute; top: 7pt; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 mr-auto ml-auto">
                        <div style="position: relative; display: block; max-width: 100%;">
                            <div style="padding-top: 56.25%;"><iframe alt="video di Chiara mamma a tempo pieno" aria-label="video di Chiara mamma a tempo pieno"
                                    src="https://players.brightcove.net/4090876667001/default_default/index.html?videoId=6131828682001"
                                    allowfullscreen="" allow="encrypted-media"
                                    style="position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe>
                            </div>
                        </div>

                        <p class="video-paziente-p">
                            CHIARA
                        </p>
                        <p class="video-paziente-sub">
                            MAMMA A TEMPO PIENO
                        </p>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 mr-auto ml-auto">
                        <div style="position: relative; display: block; max-width: 100%;">  
                            <div style="padding-top: 56.25%;"><iframe alt="video Jessica Studentessa" aria-label="video Jessica Studentessa"
                                    src="https://players.brightcove.net/4090876667001/default_default/index.html?videoId=6131823724001"
                                    allowfullscreen="" allow="encrypted-media"
                                    style="position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe>
                            </div>
                        </div>

                        <p class="video-paziente-p">
                            JESSICA
                        </p>
                        <p class="video-paziente-sub">
                            STUDENTESSA
                        </p>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 mr-auto ml-auto">
                        <div style="position: relative; display: block; max-width: 100%;">
                            <div style="padding-top: 56.25%;"><iframe alt="video Franco Pensionato" aria-label="video Franco Pensionato"
                                    src="https://players.brightcove.net/4090876667001/default_default/index.html?videoId=6131827787001"
                                    allowfullscreen="" allow="encrypted-media"
                                    style="position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe>
                            </div>
                        </div>

                        <p class="video-paziente-p">
                            FRANCO
                        </p>
                        <p class="video-paziente-sub">
                            PENSIONATO
                        </p>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 mr-auto ml-auto">
                        <div style="position: relative; display: block; max-width: 100%;">
                            <div style="padding-top: 56.25%;"><iframe alt="Luca Tecnico Informatico" aria-label="Luca Tecnico Informatico"
                                    src="https://players.brightcove.net/4090876667001/default_default/index.html?videoId=6131827786001"
                                    allowfullscreen="" allow="encrypted-media"
                                    style="position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe>
                            </div>
                        </div>

                        <p class="video-paziente-p">
                            LUCA
                        </p>
                        <p class="video-paziente-sub">
                            TECNICO INFORMATICO
                        </p>
                    </div>
                </div>

                <div class="row margin-top-bottom-3-2vw">
                    <div class="col-12 col-sm-12 col-md-10 col-lg-6 mr-auto ml-auto padding-0 box-msd-full">
                        <div class="margin-top-bottom-2-2vw text-center">
                            <span class="social-style">
                                <a class="nav-link nav-link-social" href="https://www.facebook.com/msdsalute/"
                                    target="_Blank" rel="noopener noreferrer"><img class="social-icon"
                                        src="./assets/img/icons/icon-facebook.png" alt="facebook"></a>
                            </span>
                            <span class="social-style">
                                <a class="nav-link nav-link-social" href="https://www.instagram.com/msd_salute/"
                                    target="_Blank" rel="noopener noreferrer"><img class="social-icon"
                                        src="./assets/img/icons/icon-instagram.png" alt="instagram"></a>
                            </span>
                            <span class="social-style">
                                <a class="nav-link nav-link-social" href="https://twitter.com/msdsalute" target="_Blank"
                                    rel="noopener noreferrer"><img class="social-icon"
                                        src="./assets/img/icons/icon-twitter.png" alt="twitter"></a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>