import { StaticService } from './../shared/services/static.service';
import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  pageContent!: SafeHtml;

  constructor(
    private staticService: StaticService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.staticService.getPrivacyPolicy().subscribe(res => {
      this.pageContent = this.domSanitizer.bypassSecurityTrustHtml(
        res.replace('%WEBSITE_URL%', environment.websiteUrl)
      );
    });
  }

}
